.selected-section {
	border: 1px dashed #90a4ae;
}

.page-section {
	position: relative;
	border-width: 0;
}

.page-section:hover {
	outline: 1px dashed #90a4ae;
}

.page-section-setting {
	display: none;
	padding: 2px;
	background-color: #bee1e6;
	position: absolute;
	left: 100%;
	top: 0;
}

.page-section:hover .page-section-setting {
	display: inline-block;
}

.page-column {
	border: none;
	min-height: 15px;
	transition: border-width 100ms ease-in;
	position: relative;
}

.page-column:hover {
	outline: 1px dashed #ff8a65;
	outline-offset: -2px;
}

.selected-column {
	transition: border-width 100ms ease-in;
	outline: 1px dashed #ff8a65;
	outline-offset: -2px;
}

.page-column-setting {
	display: none;
	padding: 2px;
	background-color: #cddafd;
	position: absolute;
	top: -16px;
	left: 0px;
}

.page-column:hover .page-column-setting {
	display: inline-block;
}

.page-component-wrapper {
	position: relative;
}

.selected-component {
	outline: 1px dashed #5c6bc0;
	outline-offset: 0px;
}

.page-component-setting {
	display: none;
	padding: 2px;
	background-color: #fad2e1;
	position: absolute;
	top: 1px;
	right: 1px;
}

.page-component-wrapper:hover {
	outline: 1px dashed #5c6bc0;
}

.page-component-wrapper:hover .page-component-setting {
	display: inline-block;
}

/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px gray;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: gray;
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #666;
}
