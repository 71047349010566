.custom-shape-divider-bottom-1656695281 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1656695281 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 500px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1656695281 .shape-fill {
    fill: #FFFFFF;
}

.custom-shape-divider-bottom-1656696063 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1656696063 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 600px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1656696063 .shape-fill {
    fill: #F5F5F5;
}

.video-sizer {
    margin-left: -18%;
}

.video-sizer video {
    max-width: 115%;
    width: 115;
}

.slick-list {
    padding: 0px !important;
}

.slick-track {
    min-width: 1000px;
}

.center .slick-center img {
    -ms-transform: scale(0.9) translateY(0px);
    transform: scale(0.9) translateY(0px);
}

.center .slick-center p {
    -ms-transform: scale(1) translateY(0px);
    transform: scale(1) translateY(0px);
}

.center img {
    -ms-transform: scale(0.8) translateY(30px);
    transform: scale(0.8) translateY(30px);
    transition: all .3s ease;
}

.center p {
    -ms-transform: scale(0.8) translateY(70px);
    transform: scale(0.8) translateY(70px);
    transition: all .3s ease;
}

@media screen and (min-width: 640px) {
    .slick-list {
        padding: 25px !important;
    }

    .slick-track {
        margin-left: 30px !important;
    }
}
