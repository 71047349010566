.chat-main-box{
    text-align: right;
}
.chat-main-box button {
    background: #fff;
    padding: 11px 11px;
    border-radius: 150px;
    color: #000;
    box-shadow: 0px 3px 8px 1px #00000040;
}

.chat-box {
    background: transparent;
}
.chat-main-box{
    background: transparent;
    position: fixed;
    right: 20px;
    bottom: 26px;
    margin-right:0;
    z-index: 99;
}
.main-chat-box{
    padding-left: 120px;
    position: absolute;
    bottom: 90px;
    right: 30px;
    z-index: 999;
}
.chat-box-contant{
    width: 350px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 1px #00000021;
    background-color: #fff;
    overflow-x: auto;
    max-height: calc(100vh - 150px);
}
.chat-box-contant::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: transparent;
}
.chat-tlint {
    position: absolute;
    top: -20px;
    right: 30px;
    transition: all 0.3s ease 0s;
}
.chat-lint-hide{
    display: none;
}
.chat-main-box-expanded .chat-tlint {
    right: 10px;
}
.chat-tlint span {
    width: 35px;
    height: 35px;
    background: #f44336;
    display: inline-block;
    position: relative;
    border-radius: 100%;
    text-align: center;
    line-height: 35px;
    color: #fff;
}
.chat-box-contant::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}
.chat-box-contant::-webkit-scrollbar-thumb {
    background-color: transparent;
}
.app-launch-heading h2 {
    text-align: center;
    color: #5e59ff;
    font-size: 20px;
}

.app-launch-heading {
    margin: 20px 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.app-launch-text{
    line-height: 42px;
    line-height: 42px;
    text-align: center;
    color: #5e59ff;
    font-size: 20px;
    padding-left: 10px;
    transition: all 0.3s ease 0s;
}
.chat-main-box-expanded .app-launch-text {
    padding: 0;
    width: 0;
    overflow: hidden;
    white-space: nowrap;
}

.chat-main-box-expanded  .clipboard {
    margin: 0;
}
.chat-main-box-expanded .app-launch-header {
    
}
.app-launch-header{
    background: white;
    padding: 20px 40px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 3px 0px #888888;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    align-items: center;
}
.clipboard{
  height: 38px;
  margin-right: 5px;
  transition: all 0.3s ease 0s;
}
.app-button{
    text-align: center;
    padding: 20px 0 0 0;
}
.launch-my-app .app-button {
    padding: 0 0 40px 0;
}

.launch-my-app .app-button button {
    width: 85%;
}
.app-button button {
    color: #fff;
    background: #5e59ff;
    padding: 10px 0px;
    border-radius: 4px;
    font-size: 12px;
    border: none;
    width: 138px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.app-button button img{
    width: 6px;
    fill: #fff;
    filter: invert(1);
    filter: invert(1);
    margin-left: 14px;
}
.l-button button{
    background:#68b04d;
}
.l-button button:disabled{
    background:#8080808c;
}
.launch-my-app{
    margin-top: 40px;
}
.l-button h2{
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: 600;
}
.css-1eziwv{
    font-weight: 800;
}
.ac-heading{
    text-align: left;
    font-weight: 800;
}
.ac-head-details{
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-left: 0;
}
.acc-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.chakra-accordion__button:hover{background-color: transparent !important;}
.chakra-accordion__button:focus{
    box-shadow: none !important;
    outline: none !important;
}
.acc-details{
    font-size: 13px;
    color: #616161;
}
.acordion-r .chakra-accordion__button svg {
    opacity: 0.5;
}
.acc-heading-details{
    display: flex;
    align-items:center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.acc-heading-details h4 {
    font-size: 13px;
    font-weight: 600;
    color: #5e59ff;
}
.acc-heading-details span{
    width: 20px;
    height: 20px;
    background-color: lightgray;
    border-radius: 100px;
}
/* .acordion-r{
    max-height: 136px;
    overflow-x: auto;
} */
.acordion-r::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: transparent;
}

.acordion-r::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

.acordion-r::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.acordion-r .chakra-accordion__button{
    padding: 5px 0px !important;
    outline: none !important;
    align-items: flex-start !important;
}
.acordion-r .chakra-accordion__item {
    padding: 0 0 20px 0;
    border: none;
}
.acc-details{
    padding-left: 20px;
}
.chakra-accordion__panel{
    padding: 5px 5px 5px 15px;
}
/* CheckBox */

/*.container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
*/
.check-box-checked div{
  margin: 8px;
}
.check-box-checked input{
    accent-color: #5e59ff;
}
/* Labels for checked inputs */
.check-box-checked input:checked + label {
    color: #616161;
    text-decoration: line-through;
}
.check-box-checked input:checked + label + p {
    color: #000000;
    text-decoration: line-through;
}
.check-box-checked label {
    margin-left: 20px;
    color: #616161;
    margin-bottom: 9px !important;
    position: relative;
    display: inline-block;
    left: 14px;
    top: -26px;
}
.check-box-checked input:checked + label:before {
    content: "";
    position: absolute;
    left: -27px;
    top: -9px;
    width: 7px;
    height: 16px;
    border: solid #29BF60;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    pointer-events: none;
}
.input-checkbox{
    appearance: none;
    box-shadow: 0px 3px 6px #00000029;
    width: 15px;
    height: 15px;
    border: 1px solid #C1C1C1;
}
.check-box-checked-outer {
    display: flex;
    align-items: flex-start;
}
.check-box-checked {
    position: relative;
    top: 6px;
}
/* model css start here */

.model-img img {
    margin: 0 auto;
    width: 150px;
}

rocket-model {
    width: 300px !important;
    height: 320px !important;
}

.rocket-main-body {
    max-width: 650px;
    border-radius: 25px !important;
}

.rocket-main-body h1 {
    text-align: center !important;
    font-size: 30px !important;
    font-weight: 800 !important;
    color: #000 !important;
}

.model-img {
    margin: 10px 0px;
}

/* .rocket-main-body p {
    text-align: center !important;
    margin: 1px 0px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    margin-bottom: 50px !important;
    color: #000 !important;
} */

.rocket-main-body .css-1iqbypn {
    right:15px !important; 
}


div#chakra-modal--body-68 {
    margin: 0 auto !important;
    background: #fff !important;
    border-radius: 8px !important;
}
/* model css end here */

.checkbox_checked {
    color: #616161;
    text-decoration: line-through;
}

.ball-scale > div {
    background-color: #f44336;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    animation-fill-mode: both;
    position: absolute;
    left: -13px;
    top: -13px;
    opacity: 0;
    margin: 0;
    width: 60px;
    height: 60px;
    animation: ball-scale 1s 0s linear infinite;
}
.ball-scale > div:nth-child(2) {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}
.ball-scale > div:nth-child(3) {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}
.background-dark {
    background-color: black;
}

@-webkit-keyframes ball-scale {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0; 
  }
    5% {
      opacity: 1; 
  }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0; 
  } 
  }
  
  @keyframes ball-scale {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0; 
  }
    5% {
      opacity: 1; 
  }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0; 
  } 
  }

@media screen and (max-width: 30em) {
    .chat-main-box{
        background: transparent;
        position: fixed;
        right: 20px;
        bottom: 80px;
        margin-right:0;
    }
}


@media only screen and (max-width: 767px) {
    .chat-box {
        display: none;
    }
}

@media only screen and (max-width: 1600px) {
    .app-launch-header {
        background: white;
        padding: 10px 15px;
        border-radius: 7px;
        display: flex;
        justify-content: center;
        box-shadow: 0px 0px 3px 0px #888888;
        cursor: pointer;
        transition: all 0.3s ease 0s;
        align-items: center;
    }

    .app-launch-text{
        line-height: 42px;
        line-height: 42px;
        text-align: center;
        color: #5e59ff;
        font-size: 16px;
        padding-left: 10px;
        transition: all 0.3s ease 0s;
    }

    .clipboard{
        height: 30px;
        margin-right: 5px;
        transition: all 0.3s ease 0s;
    }
}

@media only screen and (max-width: 1330px) {
    .app-launch-header {
        background: white;
        padding: 5px 5px;
        border-radius: 7px;
        display: flex;
        justify-content: center;
        box-shadow: 0px 0px 3px 0px #888888;
        cursor: pointer;
        transition: all 0.3s ease 0s;
        align-items: center;
    }

    .clipboard{
        height: 25px;
        margin-right: 5px;
        transition: all 0.3s ease 0s;
    }
}
