.rec .rec-slider-container {
    margin: 0;
}

.rec .rec-pagination {
    position: absolute;
    bottom: 10px;
}

.rec .rec-dot {
    box-sizing: border-box;
    padding: 0;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    border: none;
    margin: 5px;
    background-color: transparent;
    font-size: 1.3em;
    content: "";
    height: 8px;
    width: 8px;
    box-shadow: 0 0 1px 2px rgb(0 0 0 / 50%);
    border-radius: 50%;
    outline: none;
}
.rec .rec-dot .rec .rec-dot_active {
    box-sizing: border-box;
    padding: 0;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    border: none;
    margin: 5px;
    background-color: rgb(158 158 158);
    font-size: 1.3em;
    content: "";
    height: 8px;
    width: 8px;
    box-shadow: 0 0 0px 4px rgb(197 197 197);
    border-radius: 50%;
    outline: none;
}