.react-paginate {
	padding: 0;
	list-style-type: none;
	display: flex;
	align-items: center;
}

.react-paginate li:not(:last-child) {
	margin-right: 12px;
}

.react-paginate li a {
	display: block;
	height: 40px;
	width: 40px;
	text-align: center;
	background-color: #f3f3f3;
	color: #454545;
	line-height: 40px;
	border-radius: 50%;
}

.react-paginate li a:hover {
	background-color: #efefef;
}

.react-paginate li a svg {
	display: inline-block;
}

.react-paginate li.selected a {
	background-color: #5e59ff;
	cursor: default;
	color: #fff;
}

.react-paginate li.disabled a {
	opacity: 0.5 !important;
}

.react-paginate li.disabled a,
.react-paginate li.disabled a:hover,
.react-paginate li.break a:hover,
.react-paginate li.break a {
	background-color: #f3f3f3;
	cursor: default;
}
